import { environment } from '../../../environments/environment';

export class Api {
  public static host = environment.apiUrl;
  public static requests = {
    accounts: {
      getAllAccount: '/api/User/admin/get_all',
      deleteUser: '/api/User/admin',
      editUser: '/api/User/admin',
      editAvatar: '/api/User/admin',
      getChatsFromAccount: '/api/Chats/admin/user',
      addChat: '/api/Chats/admin/user/{userId}/{chatId}/add-chat',
      deleteChat: '/api/Chats/admin/user/{userId}/{chatId}/remove-chat',
      makeChatFree: '/api/Chats/admin/user/{userId}/{chatId}/off-trial-chat',
    },
    bonuses: {
      getAllBonuses: '/api/Reward/admin',
      editBonuse: '/api/Reward/admin',
    },
    products: {
      getAllGems: '/api/Chatlot/gems',
      chatlot: '/api/Chatlot',
      getChatlot: '/api/Chatlot/admin/all',
      getSpecials: '/api/Chatlot/admin/specials',
      createGem: '/api/Chatlot/gems',
      saveAvatar: '/api/Chatlot/admin/{id}/save-avatar',
    },
    variables: {
      monetization: '/api/Settings/monetization',
    },
    chats: {
      chats: '/api/Chats/admin',
      deleteChat: '/api/Chats/',
      uploadChatAvatar: '/api/Chats/admin/{id}/save-avatar',
      media: '/api/ChatContent/{chatId}',
      //media: '/api/ChatContent/test',
      editProbability: '/api/Chats/edit_probability',
      updateOrdering: '/api/Chats/admin/update-ordering',
    },
    cards: {
      cards: '/api/Cards/admin/chat/{id}',
      editCard: '/api/Cards/admin',
      deleteCard: '/api/Cards/admin',
    },
    general: {},
    messages: {
      getMessages: '/api/admin/Message/chat',
      message: '/api/admin/Message',
      clearMessages: '/api/Chats/admin/{chatId}/clear-messages',
      importMessages: '/api/admin/Message/chat/{chatId}/import-messages',
      exportMessages: '/api/admin/Message/chat/{chatId}/export-messages',
      messageOrder: '/api/admin/Message/chat/{chatId}/change-order',
    },
    auth: {
      login: '/api/Auth/signin',
      registration: '/api/Auth/admin/signup',
    },
    settings: {
      setVersions: '/api/Settings/set-versions',
      getVersions: '/api/Settings/get-versions',
    },
    profile: {
      profile: '/api/Profile/{chatId}',
      saveProfile: '/api/Profile/admin',
      profileLinkNew: '/api/Profile/{chatId}/link',
      profileLinkEdit: '/api/Profile/{chatId}/link/{linkId}',
    },
  };
  public static codes = {
    logicSuccess: '1001',
  };
}
