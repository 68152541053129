// Vendors
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UnsubscrubeHelper {
    constructor() { }

    public unsubscribe(array: any[]): void {
        for (let i = 0; i < array.length; i++) {
            const el = array[i];

            if (el && el.unsubscribe) {
                el.unsubscribe();
            }
        }
    }
}
