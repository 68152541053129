import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { environment } from '../environments/environment';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template:
    '<router-outlet></router-outlet><notifier-container></notifier-container>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private renderer: Renderer2,
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    console.log('ENV', environment);

    switch (environment.buildVersion) {
      case 'prod':
        this.renderer.addClass(document.body, 'version-prod');
        break;
      case 'nutaku':
        this.renderer.addClass(document.body, 'version-nutaku');
        break;
      case 'test':
        this.renderer.addClass(document.body, 'version-test');
        break;
      case 'legal':
        this.renderer.addClass(document.body, 'version-legal');
        break;
    }
  }
}
