// Vendors
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// Helpers
import { NavHelper } from '../helpers/nav.helper';
// Services
import { AuthService } from '../services/api-services';

@Injectable({
  providedIn: 'root'
})
export class TokenGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private _navHelper: NavHelper
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentUser = this._authService.getCurrentUser();

    if (!currentUser) {
      this._navHelper.goToLogin();
      return false;
    }

    return true;
  }

}
