// Vendors
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavHelper {
    constructor(
        private router: Router
    ) { }

    public goToLogin(): void {
        this.router.navigateByUrl('/login');
    }

    public goToChats(): void {
        this.router.navigateByUrl('/chats');
    }

    public goToNewChat(): void {
        this.router.navigateByUrl('/new-chat');
    }

    public goToChat(id: string): void {
        this.router.navigateByUrl(`/chat/${id}`);
    }
}
