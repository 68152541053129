<div class="substate" *ngIf="selectedImageUrl">
    <div class="modal fade show image-modal" id="exampleModalLong" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLongTitle">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Chat Avatar</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleImagePreview()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <img *ngIf="selectedImageUrl" [attr.src]="selectedImageUrl" alt="avatar">
                </div>
            </div>
        </div>
    </div>
</div>