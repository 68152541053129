import { BonusesModule } from './views/bonuses/bonuses.module';
import { AccountService } from './shared/services/api-services/account.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  IconModule,
  IconSetModule,
  IconSetService,
} from '@coreui/icons-angular';

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
// Services
import { AuthService } from './shared/services/api-services';
// Interceptors
import {
  HttpErrorInterceptor,
  HttpResponceInterceptor,
  TokenInterceptor,
} from './shared/interceptors';
// Modules
import { ImagePreviewModule } from './shared/components/image-preview/image-preview.module';
import { BonusesService } from './shared/services/api-services/bonuses.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SortablejsModule } from 'ngx-sortablejs';
import { NotifierModule } from 'angular-notifier';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    HttpClientModule,
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    ImagePreviewModule,
    ModalModule.forRoot(),
    SortablejsModule.forRoot({}),
    NotifierModule.withConfig({
      position: {
        horizontal: { position: 'right' },
        vertical: { position: 'top' },
      },
    }),
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponceInterceptor,
      multi: true,
    },

    IconSetService,
    AuthService,
    AccountService,
    BonusesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
