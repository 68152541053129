// Vendors
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Routing
import { ImagePreviewRoutingModule } from './image-preview-routing.module';
// Components
import { ImagePreviewComponent } from './image-preview.component';
// Services
import { ImagePreviewService } from '../../services/comunication-services';


@NgModule({
  declarations: [ImagePreviewComponent],
  imports: [
    CommonModule,
    ImagePreviewRoutingModule
  ],
  exports: [ImagePreviewComponent],
})
export class ImagePreviewModule { }
