// Vendors
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Data
import { Api } from '../../data';
// Models
import { UserClientModel } from '../../models';

class LoginModel {
    code: string;
    message: string;
    succeeded: boolean;
    token: string;
    user: UserClientModel;
}

@Injectable()
export class AuthService {
    constructor(
        private _http: HttpClient,
    ) { }

    public getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    public login(email: string, password: string): Observable<LoginModel> {
        const request = Api.requests.auth.login;

        return this._http.post<LoginModel>(`${Api.host}${request}`, { email, password });
    }

    public logout(): void {
        localStorage.removeItem('user');
    }
}
