// Vendors
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Data
import { Api } from '../../data';
// Models
import { UserClientModel } from '../../models';

@Injectable()
export class BonusesService {
    constructor(
        private _http: HttpClient,
    ) { }

    public getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    public getAllBonuses(): Promise<any> {
        return new Promise<boolean>(async (resolve) => {
            const requestUrl = `${Api.host}${Api.requests.bonuses.getAllBonuses}`;
            const response = await this._http.get<any>(requestUrl).toPromise();
            resolve(response);
            return response;
        });
    }

    public editBonuse(id: string, data: any): Promise<any> {
        return new Promise<boolean>(async (resolve) => {
            const requestUrl = `${Api.host}${Api.requests.bonuses.editBonuse}/${id}`;
            const response = await this._http.put<any>(requestUrl, data).toPromise();
            resolve(response);
            return response;
        });
    }

}