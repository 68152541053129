// Vendors
import { Component, OnDestroy, OnInit } from '@angular/core';

// Helpers
import { UnsubscrubeHelper } from '../../helpers';
// Services
import { ImagePreviewService } from '../../services/comunication-services';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit, OnDestroy {
  private _subscribtion = [];
  public selectedImageUrl: string;

  constructor(
    private _imagePreviewService: ImagePreviewService,
    private _unsubscrubeHelper: UnsubscrubeHelper
  ) { }

  ngOnInit(): void {
    this.subscribeOnToggler();
  }

  ngOnDestroy(): void {
    this._unsubscrubeHelper.unsubscribe(this._subscribtion);
  }

  private subscribeOnToggler(): void {
    this._subscribtion.push(this._imagePreviewService.imageUrl.subscribe((url: string) => {
      this.selectedImageUrl = url;
    }));
  }

  public toggleImagePreview(): void {
    this.selectedImageUrl = null;
  }

}
