<!-- [navbarBrandFull]="{src: '', width: 89, height: 25, alt: 'CoreUI Logo'}" -->
<app-header [fixed]="true" [sidebarToggler]="'lg'" [asideMenuToggler]="'lg'">
  <div style="padding-left: 20px;">
    Admin panel version: 
    <b class="header-v-nutaku">NUTAKU PROD</b>
    <b class="header-v-test">TEST</b>
    <b class="header-v-prod">PROD</b>
    <b class="header-v-legal-test">LEGAL TEST</b>
    <b class="header-v-legal">LEGAL PROD</b>
  </div>

  <div style="padding-left: 20px;" class="go-to-admin-version-block">
    <span *ngIf="environment.buildVersion === 'test'">Go to <a  class="go-to-test" href="https://testgame.getmynudes.com" target="_blank">Game</a></span>
    <span *ngIf="environment.buildVersion === 'prod'">Go to <a class="go-to-prod" href="https://game.getmynudes.com" target="_blank">Game</a></span>
    <span *ngIf="environment.buildVersion === 'nutaku'">Go to <a class="go-to-nutaku" href="https://nutaku.getmynudes.com" target="_blank">Game</a></span>
    <span *ngIf="environment.buildVersion === 'legal'">Go to <a class="go-to-legal" href="https://game.lovechats.com" target="_blank">Game</a></span>
    <span *ngIf="environment.buildVersion === 'legalTest'">Go to <a class="go-to-legal-test" href="https://testgame.lovechats.com" target="_blank">Game</a></span>
    
    
    
  </div>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item d-md-down-none">
      <div style="padding-left: 20px;" class="go-to-admin-version-block">
        Go to 
        <a *ngIf="environment.buildVersion !== 'nutaku'" class="go-to-nutaku" href="https://nutakuadmin.getmynudes.com" target="_blank">Nutaku</a>
        <a *ngIf="environment.buildVersion !== 'prod'" class="go-to-prod" href="https://admin.getmynudes.com" target="_blank">Prod</a>
        <a *ngIf="environment.buildVersion !== 'test'" class="go-to-test" href="https://testadmin.getmynudes.com" target="_blank">Test</a>
        <a *ngIf="environment.buildVersion !== 'legal'" class="go-to-legal" href="https://admin.flirtext.com" target="_blank">Legal</a>
        <a *ngIf="environment.buildVersion !== 'legalTest'" class="go-to-legal-test" href="https://testadmin.flirtext.com" target="_blank">Legal Test</a>
        admin panel
      </div>
    </li>
    <li class="nav-item dropdown" placement="bottom right">
      <!-- <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" /> -->
      <button type="button" class="btn btn-danger logout" (click)="logout()">Logout</button>
      <!-- <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span
            class="badge badge-info">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span
            class="badge badge-success">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span
            class="badge badge-danger">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comment<span
            class="badge badge-warning">42</span></a>
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" href="#"><i class="fa fa-wrench"></i> Setting</a>
        <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-dark">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span
            class="badge badge-primary">42</span></a>
        <div class="divider"></div>
        <a class="dropdown-item" href="#"><i class="fa fa-shield"></i> Lock account</a>
        <a class="dropdown-item" href="#"><i class="fa fa-lock"></i> Logout</a>
      </div> -->
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->

    <!-- deprecation warning for 'app-breadcrumb' -->
    <!--<ol class="breadcrumb">-->
    <!--<app-breadcrumb></app-breadcrumb>-->
    <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
    <!--<li class="breadcrumb-menu d-md-down-none">-->
    <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
    <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
    <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
    <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
    <!--</div>-->
    <!--</li>-->
    <!--</ol>-->
    <div class="container-fluid">
      <app-image-preview></app-image-preview>
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
  <!-- <app-aside [fixed]="true" [display]="false" [ngClass]="'test'">
    <tabset>
      <tab>
        <ng-template tabHeading><i class="icon-list"></i></ng-template>
        <div class="list-group list-group-accent">
          <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Today</div>
          <div class="list-group-item list-group-item-accent-warning list-group-item-divider">
            <div class="avatar float-right">
              <img class="img-avatar" src="assets/img/avatars/7.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div>Meeting with
              <strong>Lucas</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  1 - 3pm</small>
            <small class="text-muted">
              <i class="icon-location-pin"></i>  Palo Alto, CA</small>
          </div>
          <div class="list-group-item list-group-item-accent-info">
            <div class="avatar float-right">
              <img class="img-avatar" src="assets/img/avatars/4.jpg" alt="admin@bootstrapmaster.com">
            </div>
            <div>Skype with
              <strong>Megan</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  4 - 5pm</small>
            <small class="text-muted">
              <i class="icon-social-skype"></i>  On-line</small>
          </div>
          <div class="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small">Tomorrow</div>
          <div class="list-group-item list-group-item-accent-danger list-group-item-divider">
            <div>New UI Project -
              <strong>deadline</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  10 - 11pm</small>
            <small class="text-muted">
              <i class="icon-home"></i>  creativeLabs HQ</small>
            <div class="avatars-stack mt-2">
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/2.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/3.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/4.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/5.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/6.jpg" alt="admin@bootstrapmaster.com">
              </div>
            </div>
          </div>
          <div class="list-group-item list-group-item-accent-success list-group-item-divider">
            <div>
              <strong>#10 Startups.Garden</strong> Meetup</div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  1 - 3pm</small>
            <small class="text-muted">
              <i class="icon-location-pin"></i>  Palo Alto, CA</small>
          </div>
          <div class="list-group-item list-group-item-accent-primary list-group-item-divider">
            <div>
              <strong>Team meeting</strong>
            </div>
            <small class="text-muted mr-3">
              <i class="icon-calendar"></i>  4 - 6pm</small>
            <small class="text-muted">
              <i class="icon-home"></i>  creativeLabs HQ</small>
            <div class="avatars-stack mt-2">
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/2.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/3.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/4.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/5.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/6.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/7.jpg" alt="admin@bootstrapmaster.com">
              </div>
              <div class="avatar avatar-xs">
                <img class="img-avatar" src="assets/img/avatars/8.jpg" alt="admin@bootstrapmaster.com">
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading><i class="icon-speech"></i></ng-template>
        <div class="p-3">
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
          <hr>
          <div class="message">
            <div class="py-3 pb-5 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">Lukasz Holeczek</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
          </div>
        </div>
      </tab>
      <tab>
        <ng-template tabHeading><i class="icon-settings"></i></ng-template>
        <div class="p-3">
          <h6>Settings</h6>
          <div class="aside-options">
            <div class="clearfix mt-4">
              <small><b>Option 1</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input" checked>
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
            <div>
              <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 2</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input">
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
            <div>
              <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 3</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input">
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                <span class="switch-handle"></span>
              </label>
            </div>
          </div>
          <div class="aside-options">
            <div class="clearfix mt-3">
              <small><b>Option 4</b></small>
              <label class="switch switch-label switch-pill switch-success switch-sm float-right">
                <input type="checkbox" class="switch-input" checked>
                <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
              </label>
            </div>
          </div>
          <hr>
          <h6>System Utilization</h6>
          <div class="text-uppercase mb-1 mt-4"><small><b>CPU Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">348 Processes. 1/4 Cores.</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>Memory Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-warning" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">11444GB/16384MB</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>SSD 1 Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">243GB/256GB</small>
          <div class="text-uppercase mb-1 mt-2"><small><b>SSD 2 Usage</b></small></div>
          <div class="progress progress-xs">
            <div class="progress-bar bg-success" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small class="text-muted">25GB/256GB</small>
        </div>
      </tab>
    </tabset>
  </app-aside> -->
</div>