// Vendors
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Api
import { Api } from '../../data';
// Models
import { ProbabilityModel } from '../../models';

@Injectable()
export class MonetizationService {
    constructor(
        private _http: HttpClient,
    ) { }

    public getMonetization(): Observable<ProbabilityModel> {
        const request = Api.requests.variables.monetization;

        return this._http.get<ProbabilityModel>(`${Api.host}${request}`);
    }

    public editMonetization(probability): Observable<ProbabilityModel> {
        const request = Api.requests.variables.monetization;

        return this._http.put<ProbabilityModel>(`${Api.host}${request}`, probability);
    }
}
