// Vendors
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Data
import { Api } from '../../data';
// Enums
import { EChatTypes } from '../../enums';
// Models
import {
  ChatModel,
  ChatOrderModel,
  ChatType,
  EditChatModel,
  EditProbabilityApiModel,
  NewChatModel,
  NewChatModelNew,
  ProbabilityOfEventsModel,
  MediaFile,
} from '../../models';

export enum CardsType {
  free = 'Free',
  secret = 'Secret',
  real = 'Real',
  soft = 'Soft',
  trial = 'Trial',
  fake = 'Fake',
  premium = 'Premium',
}
export interface CardResponse {
  id: string;
  name: string;
  description: string;
  type: CardsType;
  chatId: string;
  image1Url: string;
  image2Url: string;
  image3Url: string;
  priority: number;
  useInTutorial: boolean;
  userPreference: string;
}
export interface GetTinderCardsResponse {
  chatId?: string;
  description: string;
  id: string;
  image1Url: string;
  image2Url: string;
  image3Url: string;
  name: string;
  type: ChatType;
  priority: number;
  useInTutorial: boolean;
  userPreference: string;
}

export interface UpdateCardData {
  chatId: string;
  name: string;
  description: string;
  type: CardsType;
  priority: number;
  useInTutorial?: boolean;
  image1Url: string;
  image2Url: string;
  image3Url: string;
  userPreference: string;
}

@Injectable()
export class ChatService {
  constructor(private _http: HttpClient) {}

  public getChatById(id): Observable<ChatModel> {
    const request = Api.requests.chats.chats;

    return this._http.get<ChatModel>(`${Api.host}${request}/${id}`);
  }

  public getAllChats(chatType?: string): Observable<ChatModel[]> {
    const request = Api.requests.chats.chats;

    if (!chatType) {
      chatType = EChatTypes.all;
    }

    return this._http.get<ChatModel[]>(
      `${Api.host}${request}/?chatType=${chatType}`,
    );
  }

  public updateOrdering(ordering: ChatOrderModel[]): Observable<any> {
    const request = Api.requests.chats.updateOrdering;

    return this._http.post<any>(`${Api.host}${request}`, {
      elements: ordering,
    });
  }

  public editChat(chat: EditChatModel, id: string): Observable<ChatModel> {
    const request = Api.requests.chats.chats;

    return this._http.put<ChatModel>(`${Api.host}${request}/${id}`, chat);
  }

  public getTinderCardsForChat(id: string): Observable<GetTinderCardsResponse> {
    let request = Api.requests.cards.cards;
    request = request.replace('{id}', id);
    return this._http.get<GetTinderCardsResponse>(`${Api.host}${request}`);
  }

  public saveTinderCardForChat(
    data: GetTinderCardsResponse,
  ): Observable<boolean> {
    let request = Api.requests.cards.editCard;
    return this._http
      .put<any>(`${Api.host}${request}`, { ...data })
      .pipe(map(() => true));
  }

  public editProbability(
    data: EditProbabilityApiModel,
  ): Observable<ProbabilityOfEventsModel> {
    const request = Api.requests.chats.editProbability;

    return this._http.put<ProbabilityOfEventsModel>(
      `${Api.host}${request}`,
      data,
    );
  }

  public createNewChat(chat: NewChatModelNew): Observable<any> {
    const request = Api.requests.chats.chats;

    return this._http.post<any>(`${Api.host}${request}`, chat);
  }

  public uploadChatAvatar(form: FormData, id): Observable<any> {
    let request = Api.requests.chats.uploadChatAvatar;
    request = request.replace('{id}', id);

    return this._http.put<any>(`${Api.host}${request}`, form);
  }

  public uploadMedia(form: FormData, chatId: string): Observable<any> {
    let request = Api.requests.chats.media;
    request = request.replace('{chatId}', chatId);
    return this._http.put<any>(`${Api.host}${request}`, form);
  }

  public getMedia(chatId: string): Observable<MediaFile[]> {
    let request = Api.requests.chats.media;
    request = request.replace('{chatId}', chatId);
    return this._http.get<MediaFile[]>(`${Api.host}${request}`);
  }

  public deleteChat(id: string): Observable<any> {
    const request = Api.requests.chats.deleteChat;

    return this._http.delete<any>(`${Api.host}${request}${id}`);
  }

  public getCards(): Observable<CardResponse[]> {
    const request = Api.requests.cards.editCard;

    return this._http.get<CardResponse[]>(`${Api.host}${request}`);
  }

  public deleteCard(id: string): Observable<boolean> {
    const request = Api.requests.cards.editCard;

    return this._http
      .delete<CardResponse[]>(`${Api.host}${request}?id=${id}`)
      .pipe(map(() => true));
  }

  public updateCard(card: UpdateCardData): Observable<CardResponse> {
    const request = Api.requests.cards.editCard;

    return this._http.put<CardResponse>(`${Api.host}${request}`, card);
  }
}
