// Vendors
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// Api
import { Api } from '../../data';
// Models
import { MessageClientModel } from '../../models';
import { ChangeMessageOrderModel } from '../../models/message/changeOrder.model';

@Injectable()
export class MessageService {
  constructor(private _http: HttpClient) {}

  public getMessagesByChatId(id: string): Observable<MessageClientModel[]> {
    const request = Api.requests.messages.getMessages;

    return this._http.get<MessageClientModel[]>(`${Api.host}${request}/${id}`);
  }

  public deleteMessage(id: string): Observable<any> {
    const request = Api.requests.messages.message;

    return this._http.delete(`${Api.host}${request}/${id}`);
  }

  public deleteAllMessages(chatId: string): Observable<any> {
    let request = Api.requests.messages.clearMessages;
    request = request.replace('{chatId}', chatId);

    return this._http.delete<any>(`${Api.host}${request}`);
  }

  public importMessages(
    chatId: string,
    form: FormData,
  ): Observable<MessageClientModel[]> {
    let request = Api.requests.messages.importMessages;
    request = request.replace('{chatId}', chatId);

    return this._http.put<MessageClientModel[]>(`${Api.host}${request}`, form);
  }

  public editMessage(
    message: MessageClientModel,
  ): Observable<MessageClientModel> {
    const request = Api.requests.messages.message;

    return this._http.put<MessageClientModel>(`${Api.host}${request}`, message);
  }

  public addNewMessage(
    message: MessageClientModel,
  ): Observable<MessageClientModel> {
    const request = Api.requests.messages.message;
    return this._http.post<MessageClientModel>(
      `${Api.host}${request}`,
      message,
    );
  }

  public exportMessages(chatId: string) {
    let request = Api.requests.messages.exportMessages;
    request = request.replace('{chatId}', chatId);
    return this._http.get(`${Api.host}${request}`, { responseType: 'blob' });
  }

  public changeMessageOrder(chatId: string, payload: ChangeMessageOrderModel) {
    let request = Api.requests.messages.messageOrder;
    request = request.replace('{chatId}', chatId);
    return this._http.put(`${Api.host}${request}`, payload);
  }
}
