// Vendors
import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

// Helpers
import { NavHelper } from '../../shared/helpers';
// Services
import { AuthService } from '../../shared/services/api-services';
// Nav items
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  public environment = environment;

  constructor(
    private _authService: AuthService,
    private _navHelper: NavHelper
  ) { }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  public logout(): void {
    this._authService.logout();
    this._navHelper.goToLogin();
  }
}
