// Vendors
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

// Helpers
import { UnsubscrubeHelper, NavHelper } from '../../shared/helpers';
// Services
import { AuthService } from '../../shared/services/api-services';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  private subscribtions = [];

  constructor(
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _unsubscrubeHelper: UnsubscrubeHelper,
    private _navHelper: NavHelper,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this._unsubscrubeHelper.unsubscribe(this.subscribtions);
  }

  private initForm(): void {
    this.form = this._formBuilder.group({
      email: new UntypedFormControl('', {
        validators: [Validators.required],
      }),
      password: new UntypedFormControl('', {
        validators: [Validators.required],
      }),
    });
  }

  public login(): void {
    const form = this.form;

    if (form.valid) {
      const email = form.value.email;
      const password = form.value.password;

      this.subscribtions.push(
        this._authService.login(email, password).subscribe((loginRes) => {
          if (loginRes && loginRes.token) {
            const token = loginRes.token;
            localStorage.setItem('user', JSON.stringify(token));

            this._navHelper.goToChats();
          }
        }),
      );
    }
  }
}
