// Vendors
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Data
import { Api } from '../../data';
// Models
import { UserClientModel } from '../../models';
import { AccountModel, AccountRole } from '../../models/account/account.model';
import { ExtendedAccountModel } from '../../models/account/extendedAccountModel';

@Injectable()
export class AccountService {
  constructor(private _http: HttpClient) {}

  public getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  public getAccounts(
    page: number,
    pageSize = 500,
    search = '',
  ): Promise<{
    elements: AccountModel[];
    elementsOnPageAmount: number;
    pageSize: number;
    totalPagesAmount: number;
  }> {
    return new Promise<{
      elements: AccountModel[];
      elementsOnPageAmount: number;
      pageSize: number;
      totalPagesAmount: number;
    }>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.getAllAccount}?search=${search}&pageSize=${pageSize}&page=${page}`;
      const response = await this._http.get<any>(requestUrl).toPromise();
      resolve(response);
      return response;
    });
  }

  public editAccount(data: any): Promise<any> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.editUser}/${data.id}`;
      const response = await this._http.put<any>(requestUrl, data).toPromise();
      resolve(response);
      return response;
    });
  }

  public async updateAvatar(file: any, index: number): Promise<string> {
    return new Promise<string>(async (resolve) => {
      const formData = new FormData();
      formData.append('file', file);
      const requestUrl = `${Api.host}${Api.requests.accounts.editAvatar}/${index}/edit_avatar`;
      const response = await this._http
        .put<any>(requestUrl, formData)
        .toPromise();
      response.avatar += '?t=' + new Date().getTime();
      resolve(response.avatar);
      return response.avatar;
    });
  }

  public deleteAccount(id): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.deleteUser}/${id}`;
      const response = await this._http.delete<any>(requestUrl).toPromise();
      resolve(true);
      return true;
    });
  }
  public async getExtendedAccountInfo(
    id: string,
  ): Promise<ExtendedAccountModel> {
    return this._http
      .get<ExtendedAccountModel>(
        `${Api.host}${Api.requests.accounts.editUser}/${id}`,
      )
      .toPromise();
  }
  public getChatsFromAccount(id: string): Promise<any> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.getChatsFromAccount}/${id}`;
      const response = await this._http.get<any>(requestUrl).toPromise();
      resolve(response);
      return response;
    });
  }

  public saveChatAccessible(data: any, id: string): Promise<any> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.getChatsFromAccount}/${id}/change-accessibility`;
      const response = await this._http.put<any>(requestUrl, data).toPromise();
      resolve(response);
      return response;
    });
  }

  public addChat(userId: string, chatId: string): Promise<any> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.addChat}`;
      const request = requestUrl
        .replace('{userId}', userId)
        .replace('{chatId}', chatId);
      const response = await this._http.put<any>(request, {}).toPromise();
      resolve(response);
      return response;
    });
  }
  public deleteChat(userId: string, chatId: string): Promise<any> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.deleteChat}`;
      const request = requestUrl
        .replace('{userId}', userId)
        .replace('{chatId}', chatId);
      const response = await this._http.put<any>(request, {}).toPromise();
      resolve(response);
      return response;
    });
  }
  public makeChatFree(userId: string, chatId: string): Promise<any> {
    return new Promise<boolean>(async (resolve) => {
      const requestUrl = `${Api.host}${Api.requests.accounts.makeChatFree}`;
      const request = requestUrl
        .replace('{userId}', userId)
        .replace('{chatId}', chatId);
      const response = await this._http.put<any>(request, {}).toPromise();
      resolve(response);
      return response;
    });
  }
  public updateRole(userId: string, role: AccountRole): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await this._http
          .put<any>(
            `${Api.host}${Api.requests.accounts.editUser}/${userId}/role`,
            { role },
          )
          .toPromise();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
  public updateCoins(userId: string, coins: number): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await this._http
          .put<any>(
            `${Api.host}${Api.requests.accounts.editUser}/${userId}/coins`,
            { coins },
          )
          .toPromise();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateSubscription(
    userId: string,
    action: 'Active' | 'None',
  ): Promise<boolean> {
    const method =
      action === 'Active' ? 'add-subscription' : 'cancel-subscription';
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await this._http
          .put<any>(
            `${Api.host}${Api.requests.accounts.editUser}/${userId}/${method}`,
            {},
          )
          .toPromise();
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }
}
