// Vendors
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// Enums
import { EHttpErrorStatuses } from '../enums';
// Helpers
import { NavHelper } from '../helpers';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class HttpResponceInterceptor implements HttpInterceptor {
  constructor(
    private _navHelper: NavHelper,
    private _notifierService: NotifierService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        console.log(event);

        if (
          event instanceof HttpResponse &&
          event.body &&
          event.body.status === EHttpErrorStatuses.error
        ) {
          this._navHelper.goToLogin();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this._notifierService.notify(
          'error',
          `Server error (${error.status} ${error.statusText})`,
        );
        return throwError(error);
      }),
    );
  }
}
