// Vendors
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImagePreviewService {
    public imageUrl = new Subject();

    constructor() { }

    public openImage(url: string): void {
        this.imageUrl.next(url);
    }

    public closeImage(): void {
        this.imageUrl.next(null);
    }
}
