// Vendors
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Data
import { Api } from '../../data';
// Models
import { ChatlotModel, CreateChatlotApiModel, EditChatLotModel } from '../../models';

@Injectable()
export class ChatlotService {
    constructor(
        private _http: HttpClient,
    ) { }

    public getGems(): Observable<ChatlotModel[]> {
        const request = Api.requests.products.getAllGems;

        return this._http.get<ChatlotModel[]>(`${Api.host}${request}`);
    }

    public editLot(lot: EditChatLotModel): Observable<ChatlotModel> {
        const request = Api.requests.products.chatlot;

        return this._http.put<ChatlotModel>(`${Api.host}${request}`, lot);
    }

    public createChatlot(chatlot: CreateChatlotApiModel): Observable<ChatlotModel> {
        const request = Api.requests.products.chatlot;

        return this._http.post<ChatlotModel>(`${Api.host}${request}`, chatlot);
    }

    public saveAvatar(id: string, formData: FormData): Observable<{ url: string }> {
        let request = Api.requests.products.saveAvatar;
        request = request.replace('{id}', id);

        return this._http.put<{ url: string }>(`${Api.host}${request}`, formData);
    }

    public createChatlotGem(lot: CreateChatlotApiModel): Observable<ChatlotModel> {
        const request = Api.requests.products.createGem;

        return this._http.post<ChatlotModel>(`${Api.host}${request}`, lot);
    }

    public deleteChatlot(id: string): Observable<any> {
        const request = Api.requests.products.chatlot;

        return this._http.delete(`${Api.host}${request}/${id}`);
    }

    public getSpecials(): Observable<ChatlotModel[]> {
        const request = Api.requests.products.getSpecials;

        return this._http.get<ChatlotModel[]>(`${Api.host}${request}`);
    }

    public getChats(): Observable<ChatlotModel[]> {
        const request = Api.requests.products.getChatlot;

        return this._http.get<ChatlotModel[]>(`${Api.host}${request}`);
    }
}
