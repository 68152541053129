// Vendors
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
// Guards
import { TokenGuard } from './shared/guards';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'chats',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page',
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [TokenGuard],
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'chats',
        loadChildren: () =>
          import('./views/chats/chats.module').then((m) => m.ChatsModule),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./views/accounts/accounts.module').then(
            (m) => m.AccountsModule,
          ),
      },
      {
        path: 'new-chat',
        loadChildren: () =>
          import('./views/new-chat/new-chat.module').then(
            (m) => m.NewChatModule,
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./views/products/products.module').then(
            (m) => m.ProductsModule,
          ),
      },
      {
        path: 'bonuses',
        loadChildren: () =>
          import('./views/bonuses/bonuses.module').then((m) => m.BonusesModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./views/settings/settings.module').then(
            (m) => m.SettingsModule,
          ),
      },
      {
        path: 'cards-sort',
        loadChildren: () =>
          import('./views/cards-sort/cards-sort.module').then(
            (m) => m.CardsSortModule,
          ),
      },
      {
        path: 'variables',
        loadChildren: () =>
          import('./views/variables/variables.module').then(
            (m) => m.VariablesModule,
          ),
      },
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then((m) => m.BaseModule),
      },
      {
        path: 'chat/:id',
        loadChildren: () =>
          import('./views/chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'media',
        loadChildren: () =>
          import('./views/media/media.module').then((m) => m.MediaModule),
      },
    ],
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
