export class Textes {
  public static tableChatsTitles = [
    'id',
    'Character Name',
    'Character Avatar',
    // 'Cost for Open',
    // "Card's priority",
    'Type',
    // 'Status',
    // 'Modified Date / Create Date',
    'User preference',
    'Platform Restriction',
  ];
  public static chatVariables = [
    'additionalContentCost',
    'cost',
    'erogamesCost',
    'nutakuCost',
    'unbanCost',
    'trialTime',
    'guestLimitMessageIndex',
    'trialLimitMessageIndex',
    'nickNameInputIndex',
    'agreementMessagesIndex',
  ];
  public static tableAccountsTitles = [
    // 'Avatar',
    'id',
    'Nickname',
    'Username',
    'Platform',
    'Email',
    'Role',
    // 'Gems',
  ];
  public static tableBonusesTitles = ['Day', 'Bonus Id', 'Gems', 'Chat id'];
  public static newChatStatuses = ['Open', 'Close'];
  public static tableShopGemsTitles = [
    'Coins',
    'Cost',
    'Erogames cost',
    'Label',
    'Centrobill',
    'SegpayId',
    'Platform Restriction',
  ];
  public static tableShopChatsTitles = [
    'Cost',
    'Erogames cost',
    'Label',
    'Centrobill',
    'SegpayId',
    'Chat',
    'Platform Restriction',
  ];
  public static tableGlobalVariablesNames = ['cp', 'cd', 'ca'];
  public static tableShowSpecial = [
    'Cost',
    'Erogames cost',
    'Avatar',
    'Centrobill',
    'SegpayId',
    'Coins',
    'Platform Restriction',
  ];
  public static userRoles = ['admin', 'user', 'guest'] as const;
  public static tableMessageTitle = [
    // 'Character Id',
    'Branch',
    'Type',
    'Display Time',
    'Message Text',
    'Content Link',
    //'Content Video Link',
    // 'Chat Id',
    //'is Additional',
    'Platform Restriction',
  ];
  public static tableChatsForSaleTitles = [
    'Character Name',
    'Character Avatar',
    'Create Date',
  ];
}
